import React from 'react'
import PropTypes from 'prop-types'
import { postContainer } from '../styles/internshipList.module.css'

const InternshipList = ({ posts }) => {
  // TODO - To be uncommented when the Internship application process is re-opened
  //
  // const openModal = () => {
  //   const modal = document.getElementById('shareModal')

  //   modal.style.display = 'block'

  //   window.onclick = function (event) {
  //     if (event.target === modal) {
  //       modal.style.display = 'none'
  //     }
  //   }
  // }

  return (
    <>
      <div className={postContainer}>
        {/* TODO - To be uncommented when the Internship application process is re-opened */}
        {/* <div>
          <span>
            <img
              alt='icon'
              src={jsIcon} />
            <h5>Applications for 2022 are now closed</h5>
          </span>
        </div> */}

        {/* <ul>
          {
            posts && posts.map((post, index) => {
              const { fullTime, title, remote, url } = post
              return (
                <li key={index.toString()}>
                  <span>
                    <span>{index + 1}</span>
                    <div>
                      <h5>{`// ${title}`}</h5>
                      <h6><span className={purple}>import</span> {`{ `}
                        {remote && <span className={blue}>remote</span>}
                        {remote && fullTime && <span>, </span>}
                        {fullTime && <span className={blue}>fullTime</span>}{` } `}
                        <span className={purple}>from</span> <span className={red}>'deimos-vacancies'</span>
                      </h6>
                    </div>
                  </span>
                  <a
                    href={url}
                    target='_blank'
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                    <span>Apply</span>
                  </a>
                </li>
              )
            })
          }
        </ul> */}
        {/* <button onClick={() => openModal()}>
          <FontAwesomeIcon icon={faShareAlt} />
          <span>share this page</span>
        </button>
        <div className={statusBar}>
          <img
            alt='awe'
            src={StatusBar} />
        </div>
      </div>
      <div
        className={modalContainer}
        id='shareModal'>
        <div className={modalContainerInner}>
          <ShareLarge
            pageName='page'
            url={window.location.href} />
        </div> */}
      </div>
    </>
  )
}

InternshipList.propTypes = {
  posts: PropTypes.array.isRequired
}

export default InternshipList
