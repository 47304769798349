import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { composeClasses, jsonId } from 'utils'
import parse from 'html-react-parser'
import SEO from 'components/seo'
import SocialLinks from 'components/socialLinks'
import InternshipList from 'components/internshipList'
import quoteLeft from 'images/quote-left.svg'
import HelmetExport from 'react-helmet'
import * as sharedStyles from '../styles/sharedLayout.module.css'
import {
  ourTeamSection,
  headerSection,
  memberCarousel,
  testimonialAuthor,
  testimonialAvatar,
  testimonialAuthorName,
  testimonialAuthorJobTitle,
  testimonialAuthorNameMobile,
  testimonialAuthorCountry
} from '../styles/careers.module.css'
import RoadmapSection from 'components/internshipRoadmapSection'
import ContentSection from 'components/contentSection'
import CarouselComponent from 'components/carousel'
import { isMobile } from 'react-device-detect'
import {
  wrapper,
  verticalContainer,
  breadCrumbsSection,
  currentInternSection,
  verticalContainerInner,
  content,
  subheading,
  applicationContainer,
  successSectionMain,
  successSection,
  successSectionTitle,
  firstWordStyle,
  successWrapper,
  internDetailsContainer,
  testimonialTopSection,
  testimonialImg,
  cardHeader,
  cardName,
  cardRole,
  quoteContainer,
  quoteStyleLeft,
  quoteStyleRight,
  sectionColumn,
  currentTestimonialHeader
} from '../styles/internship.module.css'
import dayjs from 'dayjs'

export const query = graphql`
  query($first: Int) {
    wordPress {
      pages(where: { name: "Internship" }) {
        edges {
          node {
            internshipFieldGroup {
              internshipHeroHeading
              internshipSubHeading
              applicationStartDate
              availablePositions {
                title
                url
                remote
                fullTime
              }
              whatYouGainHeading
              whatYouGainSkills {
                skillsHeading
                skillsDescription
              }
              goodFitSectionHeader
              goodFitSection {
                reasonText
              }
              currentInternSectionHeader
              successStorySectionHeader
              successStoryTestimonialDetails {
                internTestimonialName
                internTestimonialRole
                internTestimonialQuote
                internTestimonialImage {
                  sourceUrl
                }
              }
            }
            slug
            title
            internshipFieldGroup {
              roadmapSectionHeader
              roadmapTabTitles {
                siteReliabilityTabTitle
                softwareEngineersTabTitle
                seActive
                sreActive
              }
              roadmapInformation {
                roadmapTabDetails {
                  monthName
                  description
                }
                roadmapFileDownloadDetails {
                  downloadComponentHeader
                  downloadComponentDescription
                  downloadComponentButtonText
                  downloadComponentButtonLink {
                    link
                 }
                }
              }
            }
          }
        }
      }
      teamMembers(first: $first) {
        edges {
          node {
            id
            status
            slug
            membersFieldGroup {
              isIntern
              memberFirstName
              memberSurname
              memberJobTitle
              memberTestimonial
              memberAvatar {
                sourceUrl
              }
            }
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const getDateDisplay = date => {
  return `${dayjs(date).format('DD MMM YYYY, HH:mm')}`
}

const Internship = ({ data, pageContext }) => {
  const teamMembers = data.wordPress.teamMembers.edges
  const internshipData = data.wordPress.pages.edges[0].node
  const heading = internshipData.internshipFieldGroup.internshipHeroHeading
  const subHeading = internshipData.internshipFieldGroup.internshipSubHeading
  const availablePositions =
    internshipData.internshipFieldGroup.availablePositions
  const skillsHeading = internshipData.internshipFieldGroup.whatYouGainHeading
  const skills = internshipData.internshipFieldGroup.whatYouGainSkills
  const goodFitTitle = internshipData.internshipFieldGroup.goodFitSectionHeader
  const reasonSection = internshipData.internshipFieldGroup.goodFitSection
  const testimonialTitleData =
    internshipData.internshipFieldGroup.successStorySectionHeader
  const testimonialDetails =
    internshipData.internshipFieldGroup.successStoryTestimonialDetails
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const testimonialFirstWord = testimonialTitleData.split(' ')[0]
  const testimonialTitle = testimonialTitleData.substr(5, 21)
  const [applicationsOpen, setApplicationsOpen] = useState(null)
  const startDate =
    data.wordPress.pages.edges[0].node.internshipFieldGroup.applicationStartDate
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  let teamTestimonial = []
  teamMembers.map(item => {
    if (item.node.membersFieldGroup.isIntern === true) {
      teamTestimonial.push(item.node.membersFieldGroup)
    }
  })

  useEffect(() => {
    const d = new Date(startDate)
    const now = new Date()
    setApplicationsOpen(d < now)
  }, [])

  return (
    <div className={wrapper}>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/careers`,
            'name': 'Careers',
            'position': 2
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 3
          }]
        })}
      </HelmetExport>
      <SEO
        description='This page explains Deimos’ Internship Programmes and how they are used to help our members and their careers.'
        title='Internships'
      />
      <div className={composeClasses(verticalContainer, breadCrumbsSection)}>
        <div className={verticalContainerInner}>
          <Breadcrumb
            crumbLabel='Internships'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
        </div>
      </div>
      <div className={composeClasses(content, verticalContainer)}>
        <div className={verticalContainerInner}>
          <h1>{heading}</h1>
          <p className={subheading}>{subHeading}</p>
          <div className={applicationContainer}>
            {!applicationsOpen && (
              <h4>{`Applications for internships open on ${getDateDisplay(
                startDate
              )}`}</h4>
            )}

            {applicationsOpen === null && <div>loading...</div>}

            {applicationsOpen && (
              <InternshipList posts={availablePositions} />
            )}

            <br />
          </div>
        </div>
      </div>
      <ContentSection
        backgroundColor={'var(--textColor)'}
        items={skills}
        render={(item, index) => {
          const { skillsDescription, skillsHeading } = item
          return (
            <div
              className={sectionColumn}
              key={index.toString()}>
              <h5>{skillsHeading}</h5>
              <p>{skillsDescription}</p>
            </div>
          )
        }}
        title={skillsHeading}
      />
      <div
        className={composeClasses(
          sharedStyles.verticalContainer,
          ourTeamSection,
          currentInternSection
        )}
      >
        <div
          className={composeClasses(
            sharedStyles.verticalContainerInner,
            headerSection
          )}
        >
          <h2 className={currentTestimonialHeader}>
            {internshipData.internshipFieldGroup.currentInternSectionHeader}
          </h2>
          <CarouselComponent
            items={teamTestimonial}
            itemsToShow={3}
            render={(item, index) => {
              const {
                memberJobTitle,
                memberTestimonial,
                memberSurname,
                memberFirstName,
                memberAvatar
              } = item
              return (
                <div
                  className={memberCarousel}
                  key={index}
                  style={
                    isMobile && teamTestimonial.length < 3
                      ? { maxWidth: '100%' }
                      : ''
                  }
                >
                  <div className={testimonialAuthor}>
                    <img
                      className={testimonialAvatar}
                      src={memberAvatar && memberAvatar.sourceUrl}
                    />
                    <div>
                      <span
                        className={testimonialAuthorName}
                      >{`${memberFirstName &&
                        memberFirstName} ${memberSurname &&
                        memberSurname}`}</span>
                      <p className={testimonialAuthorJobTitle}>
                        {memberJobTitle}
                      </p>
                      <span className={testimonialAuthorNameMobile}>
                        {`${memberFirstName &&
                          memberFirstName} ${memberSurname &&
                          memberSurname}`}{' '}
                      </span>
                      <br className={testimonialAuthorNameMobile} />
                      <span className={testimonialAuthorCountry}>
                        {memberJobTitle}
                      </span>
                    </div>
                  </div>
                  <img
                    className={quoteStyleLeft}
                    src={quoteLeft} />
                  <span>{parse(memberTestimonial)}</span>
                  <img
                    className={quoteStyleRight}
                    src={quoteLeft} />
                </div>
              )
            }}
          />
        </div>
      </div>
      <RoadmapSection data={data} />
      <div className={successSectionMain}>
        <div className={successSection}>
          <div className={successSectionTitle}>
            <h2>
              <span className={firstWordStyle}>{testimonialFirstWord}</span>{' '}
              {testimonialTitle}
            </h2>
          </div>
          <div className={successWrapper}>
            {testimonialDetails.slice(0, 3).map((detail, key) => {
              return (
                <div
                  className={internDetailsContainer}
                  key={key.toString()}>
                  <div className={testimonialTopSection}>
                    <img
                      alt='Intern Testimonial Image'
                      className={testimonialImg}
                      height={62}
                      src={detail.internTestimonialImage.sourceUrl}
                      width={62}
                    />
                    <div className={cardHeader}>
                      <p className={cardName}>
                        {detail.internTestimonialName}
                      </p>
                      <p className={cardRole}>
                        {detail.internTestimonialRole}
                      </p>
                    </div>
                  </div>
                  <div className={quoteContainer}>
                    <img
                      className={quoteStyleLeft}
                      src={quoteLeft} />
                    {parse(detail.internTestimonialQuote)}
                    <img
                      className={quoteStyleRight}
                      src={quoteLeft} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <ContentSection
        backgroundColor={'var(--textColor)'}
        items={reasonSection}
        title={goodFitTitle}
      />
      <SocialLinks
        hasWhiteBackground
        socialLinks={footerData.socialLinks}
      />
    </div>
  )
}

Internship.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default Internship
