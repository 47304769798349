import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import {
  tabSectionMain,
  tabSection,
  tabSectionTitle,
  tablistStyle,
  detailsWrapper,
  detailsContainer,
  tabPanelFlex,
  pdfWrapper,
  pdfButtonWrapper
} from '../styles/internshipRoadmapSection.module.css'

const RoadmapSection = ({ data }) => {
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <div>
      <div
        className={tabSectionMain}>
        <div className={tabSection}>
          <div className={tabSectionTitle}>
            <h2>{data.wordPress.pages.edges[0].node.internshipFieldGroup.roadmapSectionHeader}</h2>
          </div>
          <div>
            {
              data.wordPress.pages.edges[0].node.internshipFieldGroup.roadmapTabTitles.map((info, key) => {
                return (
                  <div key={key.toString()}>
                    <Tabs
                      onSelect={index => setTabIndex(index)}
                      selectedIndex={tabIndex}>
                      <TabList className={tablistStyle}>
                        {info.sreActive && (
                          <Tab>
                            <p>{info.siteReliabilityTabTitle}</p>
                          </Tab>
                        )}
                        {info.seActive && (
                          <Tab>
                            <p>{info.softwareEngineersTabTitle}</p>
                          </Tab>
                        )}
                      </TabList>
                      {data.wordPress.pages.edges[0].node.internshipFieldGroup.roadmapInformation.map((detail, key) => (
                        <TabPanel key={key.toString()}>
                          <div className={tabPanelFlex}>
                            {detail.roadmapTabDetails.map((item, key) => (
                              <div
                                className={detailsWrapper}
                                key={key.toString()}>
                                <div className={detailsContainer}>
                                  <h4>{item.monthName}</h4>
                                  <p>{item.description}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                          {detail.downloadComponentButtonLink && detail.roadmapFileDownloadDetails.map((detail, key) => (
                            <div className={pdfWrapper}>
                              <div key={key.toString()}>
                                <h4>{detail.downloadComponentHeader}</h4>
                                <div>
                                  <p>{detail.downloadComponentDescription}</p>
                                </div>
                                <div className={pdfButtonWrapper}>
                                  <a
                                    download
                                    href={detail.downloadComponentButtonLink.link}
                                    target='_blank'
                                  >
                                    {detail.downloadComponentButtonText}
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </TabPanel>
                      ))}
                    </Tabs>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

RoadmapSection.propTypes = {
  data: PropTypes.object.isRequired
}

export default RoadmapSection
